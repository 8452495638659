<template>
	<div id='forgot-page' class="form-box row ">
		<div class="col">
		<h1>Forgot your password?</h1>
		<p class="">Enter your Email adress and we will reset your password:</p>
		<form>
			<div class="">
				<input type="email" class="form-control dark-input" placeholder="Email Your">
			</div>
			<button type="button" class="btn btn-warning btn-lg btn-block" @click="openResetPage">Reset Password</button>
		</form>
		<div class="form-link">Back to Sign In?</div>
		</div>
	</div>
</template>
<script>
export default {
	mounted() {
		var windowH = $(window).height() - 65 -65 ;
		$('#forgot-page').height(windowH)
	},
	methods: {
		openResetPage() {
			this.$router.push({ path: 'reset-password' })
		}
	}
}
</script>
<style lang='scss' scoped>
#forgot-page {
	margin-top: 30%;
}
</style>